import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createBrowserRouter, Navigate, Outlet, RouteObject, RouterProvider } from "react-router-dom";
import Dashboard from './containers/Layout/Dashboard';
import AuthLayout from "./containers/AuthLayout/AuthLayout";

import "./assets/css/bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss";
import "./assets/scss/style.scss";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { Provider } from 'react-redux';
import createStore from './utils/storeConfig';
import { createRoot } from 'react-dom/client';
import ErrorPage from './pages/ErrorPage';
import { getAuthRoutes, getDashboardRoutes } from './routes';
declare const window: any;

const store = createStore;

/*
  For different environment, to be compatible with Drakkar version, we need to copy process.env variables to window object
*/
(() => {
  /* istanbul ignore next */
  if (window.location.href.indexOf('localhost') >= 0 && window.location.href.indexOf('localhost:8080') < 0) {
    window.REACT_APP_API_ENDPOINT_SUPPORT = process.env.REACT_APP_API_ENDPOINT_SUPPORT
    window.REACT_APP_BASE_PATH = process.env.REACT_APP_BASE_PATH
    window.REACT_APP_VTV_LOGIN_API = process.env.REACT_APP_VTV_LOGIN_API
    window.REACT_APP_VTV_LOGOUT_API = process.env.REACT_APP_VTV_LOGOUT_API
    window.REACT_APP_VTV_REFRESH_API = process.env.REACT_APP_VTV_REFRESH_API
    window.REACT_APP_VTV_LOGIN_MODE = process.env.REACT_APP_VTV_LOGIN_MODE
    window.REACT_APP_MIN_LOADING_TIME = process.env.REACT_APP_MIN_LOADING_TIME
    window.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE = process.env.REACT_APP_FINITALIA_TO_SUPPORT_TOGGLE
    window.REACT_APP_API_ENDPOINT_CORE = process.env.REACT_APP_API_ENDPOINT_CORE
  }
})()

/* istanbul ignore next */
export const appLayoutRoutes: RouteObject[] = [
  {
    id: "admin",
    path: window.REACT_APP_BASE_PATH + "/admin",
    element: <Dashboard />,
    children: getDashboardRoutes(window)
  },
  {
    id: "auth",
    path: window.REACT_APP_BASE_PATH + "/auth",
    element: <AuthLayout />,
    children: getAuthRoutes(window)
  },
  {
    path: "*",
    element: <Navigate to={window.REACT_APP_BASE_PATH + "/admin/communications"} replace />
  }
];

/* istanbul ignore next */
const router = createBrowserRouter([
  {
    Component: () => <><Outlet /></>,
    children: appLayoutRoutes,
    errorElement: <ErrorPage />
  }
]);


export const App = () => <RouterProvider router={router} ></RouterProvider>;
const container: any = document.getElementById('root');
if(container) {
  const root = createRoot(container);
  root.render(<Provider store={store}>
  <App />
</Provider>)
}